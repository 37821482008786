@tailwind base;
@tailwind components;
@tailwind utilities;



*, *::before, *::after {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

:root {
    --primary-hue: 210;
    --gray-hue: 240;
    --color-primary: hsl(var(--primary-hue), 100%, 50%);
    --color-primary-variant: hsl(var(--primary-hue), 100%, 65%);
    --color-secondary: #25554D;
    --color-gray-100: hsl(var(--gray-hue), 47%, 94%);
    --color-gray-200: hsl(var(--gray-hue), 26%, 75%);
    --color-gray-300: hsl(var(--gray-hue), 24%, 64%);
    --color-gray-400: hsl(var(--gray-hue), 16%, 41%);
    --color-gray-500: hsl(var(--gray-hue), 44%, 25%);
    --color-gray-600: hsl(var(--gray-hue), 48%, 15%);

    --container-width-lg: 80%;
    --container-width-md: 90%;

    --transition: all 500ms ease;
}





/* GENERAL STYLES CSS */
body {
    font-family: 'Inter', sans-serif;
    color: var(--color-gray-200);
    line-height: 1.7;
    overflow-x: hidden;
    /* background: var(--color-gray-600); */
    background: #FFFFFF;
}

.container {
    width: var(--container-width-lg);
    max-width: 1920px;
    margin-inline: auto;
}

h1, h2, h3, h4, h5 {
    line-height: 1.2;
    color: var(--color-gray-100);
}

h1 {
    font-size: 3rem;
}

h2 {
    font-size: 2rem;
}

a {
    color: var(--color-gray-100);
}

img {
    object-fit: contain;
    display: block;
}

.btn {
    color: var(--color-gray-100);
    width: fit-content;
    margin: 0 auto;
    padding: 0.9rem 2rem;
    background: var(--color-primary);
    border-radius: 1.5rem;
    transition: var(--transition);
}

.btn:hover {
    /* background: var(--color-secondary); */
    background:#25554D;;
    color: var(--color-gray-100);
}

.btn.lg {
    padding: 1.2rem 3rem;
    border-radius: 2rem;
    font-size: 1.1rem;
}

.btn.sm {
    padding: 0.4rem 1.2rem;
    font-size: 0.9rem;
}

.btn.sm:hover {
    background: var(--color-gray-100);
}





/* section {
    margin-top: 10rem;
} */

.section_head {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.section_head span {
    background: var(--color-gray-500);
    padding: 0.8rem;
    border-radius: 1rem;
    color: var(--color-secondary);
    font-size: 1.5rem;
}

.card:hover {
    background: transparent;
    border-color: var(--color-gray-400);
    cursor: pointer;
}




.cards {
    background: transparent;
    border: 2px solid #26323c0f;
    text-align: center;
    padding-bottom: 3rem;
    border-radius: 2rem;
    transition: var(--transition);
    box-shadow: 0px 10px 20px #26323c0f;
}

.cards:hover {
    background: #25554D;
    border-color: var(--color-gray-400);
    cursor: pointer;
}

.cards span {
    width: 100%;
    height: auto;
    display: grid;
    margin-inline: auto;
    margin-bottom: 1.5rem;
}

.cards span>img {
    border-radius: 2rem 2rem 0 0;
}

.cards:hover span {
    background: var(--color-secondary);
    color: var(--color-gray-600);
    border-radius: 3rem 3rem 0 0;
}

.cards:hover h4 {
    color: #FFFFFF;
}

.cards:hover small {
    color: #FFFFFF;
}

.cards small {
    margin-top: 1rem;
    display: block;
    font-weight: 300;
}





.dynamic_img {
    height: 500px !important;
}
